import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { Title } from '../../components/Title';
import { useOnScreen } from '../../utils/UseOnScreen';
import classes from './style.module.scss';

export const Start = () => {

  const titleRef = useRef<Element>() as any;
  const lines = useRef<Array<HTMLElement | null>>([]);
  const onScreen = useOnScreen(titleRef);
  const controls = useAnimation();
  useEffect(() => {
    if (onScreen) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: .5,
          ease: 'easeInOut'
        }
      });
    }
  }, [controls, onScreen]);

  const windowWidth = window.innerWidth;
  let marginTop = 223;

  if (windowWidth <= 670) {
    marginTop = 100
  }

  const texts: {
    title: string;
    text: string 
  }[] = [
    {
      title: 'contact us',
      text: 'fill out the brief or send us an email.We are glad to welcome everyone, both clients and producers, creatives, directors and not only'
    },
    {
      title: 'ACQUAINTANCE',
      text: 'Getting to know and defining our ultimate goal.  And then we go on our journey together'
    },
    {
      title: 'Creative concept',
      text: 'The key stage in any project is the development of an idea and a script'
    },
    {
      title: `Visualization of\n the concept`,
      text: 'Visual embodiment of a creative idea or concept in a treatment, storyboard, animatics'
    },
    {
      title: 'Production process',
      text: 'Magic. The most painstaking workflow, having many sub-steps. Guidance and coordination at each stage'
    },
    {
      title: 'The final product',
      text: 'We hand over the project to your team and remain available for further guidance and support. And, of course, we are always ready to start a new project together'
    },
]

  return <section>
    <motion.div
      ref={titleRef}
      initial={{opacity: 0, y: -20}}
      animate={controls}
    >
      <Title marginTop={marginTop}>where to <span className={'highlight'}>start</span>?</Title>
    </motion.div>

    <div>
      {new Array(6).fill(1).map((item, index) => 
        <motion.div
          key={index}
          initial={{opacity: 0, y: -20}}
          animate={{...controls}}
          ref={(item) => lines.current[index] = item}
          className={classes['line']}
        >
          <div className={classes['number-container']}>
            <p className={classes['number'] + ' highlight'}>{index + 1}</p>
            <div className={classes['circle']}></div>
          </div>
          <div className={classes['text-container']}>
            <h3 className={classes['text-title']}>{texts[index].title}</h3>
            <p className={classes['text']}>
              {texts[index].text}
            </p>
          </div>
        </motion.div>
      )}
    </div>
  </section>
}