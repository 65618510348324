import { Title } from '../../components/Title';
import classes from './style.module.scss';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useOnScreen } from '../../utils/UseOnScreen';

export const Showreel = () => {
  const rootRef = useRef<Element>() as any;
  const onScreen = useOnScreen(rootRef);
  const controls = useAnimation();
  useEffect(() => {
    if (onScreen) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: .3,
          ease: 'easeInOut'
        }
      });
    }

  }, [controls, onScreen]);
  

  return <motion.section
    ref={rootRef}
    initial={{opacity: 0, y: -40}}
    animate={controls}
  >
    <Title>showreel <span className={'highlight'}>20:22</span></Title>

    <div style={{padding:'56.25% 0 0 0' ,position:'relative'}}>
      <iframe 
        id={classes['iframe']}
        src="https://player.vimeo.com/video/755920108?h=aaebf51115" 
        // style={{position:'absolute',top:0,left:0,bottom: 0, right: 0, width:'80%',height:'100%', margin: 'auto'}} 
        frameBorder="0" 
        allow="autoplay; fullscreen; picture-in-picture" 
        allowFullScreen></iframe>
    </div>
  <script src="https://player.vimeo.com/api/player.js"></script>

  </motion.section>
}