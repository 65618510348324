import { Title } from '../../components/Title';
import classes from './style.module.scss';
import puzzle from '../../assets/images/creative.svg';
import art from '../../assets/images/art.svg'
import graphics from '../../assets/images/3d.svg'
import visual from '../../assets/images/visual.svg'
import nft from '../../assets/images/nft.svg'
import avatars from '../../assets/images/avatars.svg'
import { useEffect, useRef } from 'react';
import { useOnScreen } from '../../utils/UseOnScreen';
import { motion, useAnimation } from 'framer-motion';

export const Services = () => {

  const rootRef = useRef<Element>() as any;
  const onScreen = useOnScreen(rootRef);
  const controls = useAnimation();
  useEffect(() => {
    if (onScreen) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: .5,
          ease: 'easeInOut'
        }
      });
    }

  }, [controls, onScreen]);

  const windowWidth = window.innerWidth;
  let marginTop = 213;

  if (windowWidth <= 670) {
    marginTop = 100
  }

  return <motion.section
      id="services"
      ref={rootRef}
      initial={{opacity: 0, y: -20}}
      animate={controls}
    >
    <Title marginTop={marginTop} >services</Title>

    <div className={classes['column']}>
      <div className={classes['line']}>
        <div className={classes['item']}>
          {
            windowWidth > 670 
            ? <img src={puzzle} alt="" /> 
            : <object
              type="image/svg+xml"
              data={puzzle}>
            </object> 
          } 
          <p>creative concept</p>
        </div>
        <div className={classes['item']}>
          {
            windowWidth > 670 
              ? <img src={art} alt="" /> 
              : <object
              type="image/svg+xml"
              data={art}>
            </object> 
          } 
          <p>ART DIRECTION</p>
        </div>
        <div className={classes['item']}>
          {
            windowWidth > 670 
              ? <img src={graphics} alt="" /> 
              : <object
              type="image/svg+xml"
              data={graphics}>
            </object> 
          } 
          <p>3D\2D GRAPHICS</p>
        </div>
      </div>
      <div className={classes['line']}>
        <div className={classes['item']}>
          {
            windowWidth > 670 
              ? <img src={visual} alt="" /> 
              : <object
              type="image/svg+xml"
              data={visual}>
            </object>
          } 
          <p>visual effects</p>
        </div>
        <div className={classes['item']}>
          {
            windowWidth > 670 
              ? <img src={nft} alt="" /> 
              : <object
              type="image/svg+xml"
              data={nft}>
            </object> 
          } 
          <p>nft</p>
        </div>
        <div className={classes['item']}>
          {/* <img src={avatars} alt="" /> */}
          {
            windowWidth > 670 
              ? <img src={avatars} alt="" />
              : <object
              type="image/svg+xml"
              data={avatars}>
            </object> 
          } 
          <p>avatars</p>
        </div>
      </div>
    </div>
    <p className={classes['text']}>
      Each process is individual, and in order to best meet your needs, we first define your goal, and then develop an individual project taking into account the smallest details
    </p>
  </motion.section>
};