import React from 'react';
import '../styles/App.scss';
import { Cursor } from '../components/Cursor';
import { Header } from '../components/Header';
import { AboutBlock } from '../blocks/About';

function About() {
  return (
    <div className="App">
      <Header isAbout={true} />
      <AboutBlock />


      <Cursor />
    </div>
  );
}

export default About;
