import { Title } from '../../components/Title';
import classes from './style.module.scss';
import instagram from '../../assets/images/instagram.svg';
import whatsapp from '../../assets/images/whatsapp.svg';
import telegram from '../../assets/images/telegram.svg';
import { useOnScreen } from '../../utils/UseOnScreen';
import { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

export const Contact = () => {

  const rootRef = useRef<Element>() as any;
  const onScreen = useOnScreen(rootRef);
  const controls = useAnimation();
  useEffect(() => {
    if (onScreen) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: .5,
          ease: 'easeInOut'
        }
      });
    }

  }, [controls, onScreen]);

  const windowWidth = window.innerWidth;
  let marginTop = 187;

  if (windowWidth <= 670) {
    marginTop = 60
  }

  return <motion.section 
    id="contact"
    ref={rootRef}
    initial={{opacity: 0, y: -20}}
    animate={controls}
  >
    <Title marginTop={marginTop}>contact us</Title>

    <div className={classes['contacts-container']}>
      <div className={classes['contact']}>
        <p><a href="https://www.instagram.com/2045studios/" target="_blank"><img src={instagram} alt="" /> @2045studios</a></p>
        <p><a href="https://wa.me/+79674673362" target='_blank'><img src={whatsapp} alt="" />8(967)467-33-62</a></p>
        <p><a href="https://t.me/zakadrommm" target='_blank'><img src={telegram} alt="" />8(967)467-33-62</a></p>
      </div>
      <div className={classes['contact']}>
        <p><a href="mailto:2045team@gmail.com">2045team@gmail.com</a></p>
        <p><a href="mailto:2045team@gmail.com">WORK TOGETHER</a></p>
        <p className={classes['upper']}>location: UAE // dubai</p>
      </div>
    </div>

    <div className={classes['center']}>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZqg713xcmz5ARi-t36uHL1k3lGwSIvgCc1nrlbvq4NPPf5A/viewform" target='_blank'>
        <button className={classes['button']}>
          brief
        </button>
      </a>
    </div>

    <div className={classes['copyright']}>
      <p>20:45<sup>&#169;</sup> 2022 all rights reserved</p>
    </div>
  </motion.section>
}