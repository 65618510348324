import classes from './style.module.scss';
import video from '../../assets/video/back.mp4';
import logo from '../../assets/images/logo.svg';

export const Landing = () => {
  const windowWidth = window.innerWidth;
  return <section className='landing-section'>
    <div className={classes['home']}>
      <div className={classes['video-container']}>
        {/* <img
          className={classes['video']}
          src={mock}
          alt=""
        /> */}
        <video className={classes['video']} preload="auto" autoPlay muted loop playsInline>
          <source src={video} />
        </video>
      </div>

      <div className={classes['home-text']}>
        {
          windowWidth > 670 
          ? <img className={classes['logo']} src={logo} alt="" /> 
          : <object
            className={classes['logo']}
            type="image/svg+xml"
            data={logo}>
          </object> 
        } 
        

        <h1 className={classes['title']}>CG STUDIO</h1>
        <p className={`${classes['title']} ${classes['sub-title']}`}>WITHOUT LIMITS</p>

        <p className={classes['slogan']}>we work beyond what is possible.</p>
        <p className={`${classes['slogan']} ${classes['sub-slogan']}`}>from idea to implementation, we will lead you to the result</p>

        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZqg713xcmz5ARi-t36uHL1k3lGwSIvgCc1nrlbvq4NPPf5A/viewform" target='_blank'>
          <button className={classes['button']}>
            brief
          </button>
        </a>
      </div>

    </div>
  </section>
}