import React, {useRef, useEffect} from "react";
import { isBrowser, isAndroid, isTablet, isIOS } from 'react-device-detect';

export const Cursor = () => {

  const cursorRef = useRef<any>(null)
  const cursorRef2 = useRef<any>(null)

    useEffect(()=>{
        if(isBrowser && !isTablet && !isAndroid && !isIOS) {
            let cursorTag = document.querySelector(".cursors")
            if (!cursorTag) return;
            let balls = cursorTag.querySelectorAll(".imageGradient")
            let mouseX = 0
            let mouseY = 0
            balls?.forEach((ball, index) => {

                let currentY = 0
                let currentX = 0
                let speed = 0.5 - index * 0.01

                const animate = ()=>{

                    currentX += (mouseX - (currentX-6)) * speed
                    currentY += (mouseY - (currentY-6)) * speed

                    ball.setAttribute('style', `
                      left: ${currentX}px;
                      top: ${currentY}px;
                    `);
                    if (!cursorRef.current) return; 
                    cursorRef.current.style.left = mouseX+6 + "px";
                    cursorRef.current.style.top = mouseY+6 + "px";
                    requestAnimationFrame(animate);
                }
                animate()
            })

            document.addEventListener("mousemove", (e)=>{
              if (!cursorRef.current) return;
                const {clientX, clientY} = e
                mouseX = clientX - cursorRef2.current.clientWidth / 200;
                mouseY = clientY - cursorRef2.current.clientHeight;
            })
        }
    },[]);

  return (
    <>
      <div className="cursor" ref={cursorRef}>
      </div>
      <div className="cursors" ref={cursorRef2}>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
        <div className="imageGradient"></div>
      </div>
    </>
  )
}