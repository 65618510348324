import { Link, useLocation } from 'react-router-dom';
import classes from './style.module.scss';
import logo from '../../assets/images/logo.svg';
import back from '../../assets/images/arrow-back.svg';
import { useEffect, useRef } from 'react';

interface Props {
  isAbout?: boolean
}

export const Header = ({isAbout = false}: Props) => {
  
  // const header = document.getElementById('header-container');
  const header = useRef<any>(null);
  useEffect(() => {
    const burger = document.getElementById('burger');
    let isOpen = false;

    burger?.addEventListener('click', () => {
      if (isOpen) {
        header?.current.classList.remove(classes['active']);
        isOpen = false;
      } else {
        header?.current.classList.add(classes['active']);
        isOpen = true;
      }
    })
  
  }, []);

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    }
  }, [pathname, hash, key]);
  

  return <div ref={header} id="header-container" className={classes['header-container']} >
    {
      isAbout &&
      <Link className={classes['arrow-back']} to="/"><img src={back} alt="" /></Link>
    }
    <ul className={classes['header__list']}>
      <li className={classes['logo']}>
        <Link onClick={() => {header?.current.classList.remove(classes['active']);}} to="/">
          {/* <img src={logo} alt="" /> */}
          <object
            type="image/svg+xml"
            data={logo}>
          </object> 
        </Link>
      </li>
      <li><Link onClick={() => {header?.current.classList.remove(classes['active']);}} to="/#projects">projects</Link></li>
      <li><Link onClick={() => {header?.current.classList.remove(classes['active']);}} to="/about">about</Link></li>
      <li><Link onClick={() => {header?.current.classList.remove(classes['active']);}} to="/#services">services</Link></li>
      <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSfZqg713xcmz5ARi-t36uHL1k3lGwSIvgCc1nrlbvq4NPPf5A/viewform" target='_blank'>brief</a></li>
      <li><Link onClick={() => {header?.current.classList.remove(classes['active']);}} to="/#contact">contact</Link></li>
    </ul>
    <div id="burger" className={classes['burger']}>
      <div className={classes['burger-line']}></div>
      <div className={classes['burger-line']}></div>
      <div className={classes['burger-line']}></div>
    </div>
  </div>
};