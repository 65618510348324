import logo from '../../assets/images/logo_about.svg';
import back from '../../assets/images/arrow-back.svg';
import classes from './style.module.scss';
import about from '../../assets/images/about-img.jpg';
import { Link } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useOnScreen } from '../../utils/UseOnScreen';
import { motion, useAnimation } from 'framer-motion';

export const AboutBlock = () => {

  const rootRef = useRef<Element>() as any;
  const onScreen = useOnScreen(rootRef);
  const controls = useAnimation();
  useEffect(() => {
    if (onScreen) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: .5,
          ease: 'easeInOut'
        }
      });
    }

  }, [controls, onScreen]);

  const windowWidth = window.innerWidth;

  return <motion.section
    ref={rootRef}
    initial={{opacity: 0, y: -20}}
    animate={controls}
  >
    <img className={classes['back-logo']} src={logo} alt="" />
    <div className={classes['content']}>
      <div className={classes['head']}>
        <div className={classes['arrow-container']}>
          <Link to="/"><img src={back} alt="" /></Link>
        </div>
        <div className={classes['logo-container']}>
          <Link to="/">
            {
              windowWidth > 670 
              ? <img src={logo} alt="" /> 
              : <object
                type="image/svg+xml"
                data={logo}>
              </object>
            } 
          </Link>
        </div>
      </div>

      <img className={classes['team']} src={about} alt="about cover" />
      

      <div className={classes['text-container']}>
        <p>Hey Hey!</p>
        <p>The future is here with our 20:45 studio, a world limited only by the imagination!</p>
        <p>We are a team of visual effects and animation specialists. A community of creative, easy-going guys who bring amazing results, combining all the skills and challenging the whole world.</p>
        <p>It is not always easy to understand what we are doing. However, step by step we will achieve the goal you need!</p>
        <p>Look from a different angle at what other people think is impossible. Trust us, and we will help you get rid of stereotypes with deeds, not words</p>
      </div>
    </div>
  </motion.section>
};




