import React from 'react';
import '../styles/App.scss';
import { Cursor } from '../components/Cursor';
import { Header } from '../components/Header';
import { Landing } from '../blocks/Landing';
import { Showreel } from '../blocks/Showreel';
import { Projects } from '../blocks/Projects';
import { Services } from '../blocks/Services';
import { Start } from '../blocks/Start';
import { Brands } from '../blocks/Brands';
import { Artists } from '../blocks/Artists';
import { Contact } from '../blocks/Contact';

function Main() {
  return (
    <div className="App">
      <Header />
      <Landing />
      <Showreel />
      <Projects />
      <Services />
      <Start />
      <Brands />
      <Artists />
      <Contact />

      <Cursor />
    </div>
  );
}

export default Main;
