import { Title } from '../../components/Title';
import classes from './style.module.scss';
import arrowLeft from '../../assets/images/arrow-left.svg';
import arrowRight from '../../assets/images/arrow-right.svg';
import { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useOnScreen } from '../../utils/UseOnScreen';
import { motion, useAnimation } from 'framer-motion';

const videos = [
  {
    link: 'https://www.youtube.com/embed/gwoWrNbxsd4',
    title: 'ИРИНА КАЙРАТОВНА - MVP (ft. Travoltah) [MV]'
  },
  {
    link: 'https://www.youtube.com/embed/2IGwkJDcIk8',
    title: 'MORGENSHTERN - ПОЧЕМУ? (Official Video, 2022)'
  },
  {
    link: 'https://player.vimeo.com/video/746100106?h=e8636b341a',
    // link: 'https://www.youtube.com/embed/UwxWt70af6k',
    title: 'Parimatch'
    // title: 'Коста Лакоста - Венера'
  },
  {
    link: 'https://www.youtube.com/embed/3kLvltm_oAo',
    title: 'Чай LOVARE. Відчуй красу!'
  },
  {
    link: 'https://www.youtube.com/embed/u14SHz-mVFg',
    title: 'Ivan Dorn & Vakula — Во сне'
  },
  {
    link: 'https://www.youtube.com/embed/spQ7N7jROgk',
    title: 'GAVRILINA - Хватит (ПРЕМЬЕРА КЛИПА 2022)'
  },
  {
    link: 'https://www.youtube.com/embed/gJt946CyJO0',
    title: 'Imanbek, Wiz Khalifa, KIDDO - Ordinary Life (feat. KDDK) - Official Video'
  },
  {
    link: 'https://www.youtube.com/embed/0pwHjWP6th0',
    title: 'Коста Лакоста - Алые Водопады'
  },
  {
    link: 'https://www.youtube.com/embed/-ClJy5G55sM',
    title: 'SLAVA MARLOW X FREE FIRE - МИЛЛИОН ДОРОГ (ПРЕМЬЕРА КЛИПА!!!)'
  },
  {
    link: 'https://www.youtube.com/embed/P77d6vEitDU',
    title: 'Instasamka | Алабуга Политех'
  },
  {
    link: 'https://www.youtube.com/embed/R5MBZKjpGGg',
    title: 'QONYRATBAY FAM feat. jeltoksan. - MEMO (Music Video)'
  },
  {
    link: 'https://www.youtube.com/embed/3--s84qy3IU',
    title: 'Даня Милохин & Юрий Шатунов - Не сдавайся (Премьера)'
  },
  {
    link: 'https://www.youtube.com/embed/ounmtkF04VU',
    title: 'Коста Лакоста - Баккара'
  },
]

export const Projects = () => {

  const rootRef = useRef<Element>() as any;
  const onScreen = useOnScreen(rootRef);
  const controls = useAnimation();
  useEffect(() => {
    if (onScreen) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: .5,
          ease: 'easeInOut'
        }
      });
    }

  }, [controls, onScreen]);
  


  const slides = useRef<Array<HTMLElement | null>>([]);
  const dots = useRef<Array<HTMLElement | null>>([]);
  let equalNum = 0;
  let leftStyle = 0;
  let currentNumber = 6;
  let activeSlide = slides.current[currentNumber];
  let activeDot = dots.current[currentNumber];
  let leftDot = dots.current[currentNumber - 1];
  let rightDot = dots.current[currentNumber + 1];
  activeDot?.classList.add(classes['active']);
  leftDot?.classList.add(classes['near']);
  rightDot?.classList.add(classes['near']);
  let [sliderElement, setSliderElement] = useState<HTMLElement>();
  let [dotsSliderElement, setDotsSliderElement] = useState<HTMLElement>();
  const windowWidth = window.innerWidth;
  let marginTop = 167;

  if (windowWidth <= 670) {
    marginTop = 100
  }

  const swipeLeft = (slider: HTMLElement | null | undefined, dotsSlider: HTMLElement | null | undefined) => {
    if(!slider) return;
    if (leftStyle === 600) {
      leftStyle = -600;
      if (windowWidth > 670) {
        equalNum = 240;
      } else {
        equalNum = 24
      }
      slider.setAttribute('style', `left: calc(${leftStyle}% + ${equalNum}px)`);
      dotsSlider?.setAttribute('style', `left: calc(${leftStyle/10}% + ${-equalNum/2}px)`);
      activeSlide?.classList.remove(classes['active']);
      activeDot?.classList.remove(classes['active']);
      leftDot?.classList.remove(classes['near']);
      rightDot?.classList.remove(classes['near']);
      currentNumber = slides.current.length - 1;
      activeSlide = slides.current[currentNumber];
      activeDot = dots.current[currentNumber];
      leftDot = dots.current[currentNumber - 1];
      activeSlide?.classList.add(classes['active']);
      activeDot?.classList.add(classes['active']);
      leftDot?.classList.add(classes['near']);
    } else {
      leftStyle += 100;
      if (windowWidth > 670) {
        equalNum -= 40;
      } else {
        equalNum -= 4
      }
      slider.setAttribute('style', `left: calc(${leftStyle}% + ${equalNum}px)`);
      dotsSlider?.setAttribute('style', `left: calc(${leftStyle/10}% + ${-equalNum/2}px)`);
      activeSlide?.classList.remove(classes['active']);
      activeDot?.classList.remove(classes['active']);
      leftDot?.classList.remove(classes['near']);
      rightDot?.classList.remove(classes['near']);
      currentNumber -= 1;
      activeSlide = slides.current[currentNumber];
      leftDot = dots.current[currentNumber - 1];
      rightDot = dots.current[currentNumber + 1];
      activeDot = dots.current[currentNumber];
      activeDot?.classList.add(classes['active']);
      activeSlide?.classList.add(classes['active']);
      leftDot?.classList.add(classes['near']);
      rightDot?.classList.add(classes['near']);
    }
  }

  const swipeRight = (slider: HTMLElement | null | undefined, dotsSlider: HTMLElement | null | undefined) => {
    if(!slider) return;
    if (leftStyle <= -600) {
      leftStyle = 600;
      if (windowWidth > 670) {
        equalNum = -240;
      } else {
        equalNum = -24
      }
      slider.setAttribute('style', `left: calc(${leftStyle}% + ${equalNum}px)`);
      dotsSlider?.setAttribute('style', `left: calc(${leftStyle/10}% + ${-equalNum/2}px)`);
      activeSlide?.classList.remove(classes['active']);
      activeDot?.classList.remove(classes['active']);
      leftDot?.classList.remove(classes['near']);
      rightDot?.classList.remove(classes['near']);
      currentNumber = 0;
      rightDot = dots.current[currentNumber + 1];
      activeSlide = slides.current[currentNumber];
      activeDot = dots.current[currentNumber];
      activeDot?.classList.add(classes['active']);
      activeSlide?.classList.add(classes['active']);
      rightDot?.classList.add(classes['near']);
    } else {
      leftStyle -= 100;
      if (windowWidth > 670) {
        equalNum += 40;
      } else {
        equalNum += 4
      }
      slider.setAttribute('style', `left: calc(${leftStyle}% + ${equalNum}px)`);
      dotsSlider?.setAttribute('style', `left: calc(${leftStyle/10}% + ${-equalNum/2}px)`);
      activeSlide?.classList.remove(classes['active']);
      activeDot?.classList.remove(classes['active']);
      leftDot?.classList.remove(classes['near']);
      rightDot?.classList.remove(classes['near']);
      currentNumber += 1;
      activeSlide = slides.current[currentNumber];
      activeDot = dots.current[currentNumber];
      leftDot = dots.current[currentNumber - 1];
      rightDot = dots.current[currentNumber + 1];
      activeDot?.classList.add(classes['active']);
      activeSlide?.classList.add(classes['active']);
      leftDot?.classList.add(classes['near']);
      rightDot?.classList.add(classes['near']);
    }
  }

  useEffect(() => {
    const slider = document.getElementById('projects-slider');
    const dots = document.getElementById('dots');
    const arrowLeft = document.getElementById('arrow-left');
    const arrowRight = document.getElementById('arrow-right');
    
    activeSlide = slides.current[currentNumber]
    if (!activeSlide) return;
    activeSlide.classList.add(classes['active']);
    if (!slider) return;
    if (!arrowLeft) return;
    if (!arrowRight) return;
    if (!dots) return;
    
    arrowLeft.onclick = () => {
      swipeLeft(slider, dots);
    };
    
    arrowRight.onclick = () => {
      swipeRight(slider, dots);
    };

    setSliderElement(slider);
    setDotsSliderElement(dots);

  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => { swipeRight(windowWidth <= 670 ? sliderElement : null, dotsSliderElement); },
    onSwipedRight: () => { swipeLeft(windowWidth <= 670 ? sliderElement : null, dotsSliderElement); }
  });

  return <motion.section 
    ref={rootRef} 
    id="projects"
    initial={{opacity: 0, y: -10}}
    animate={controls}
  >
    <Title marginTop={marginTop}>projects</Title>

    <div className={classes['projects-container']}>
      <img id="arrow-left" className={`${classes['arrow']} ${classes['left']}`} src={arrowLeft} alt="" />
      <div className={classes['projects']}>
        <div {...handlers} id="projects-slider" className={classes['projects-inner']}>
          {
            videos.map((proj, index) => 
            <div
              key={index}
              ref={(item) => slides.current[index] = item}  
              className={classes['project']}
            >
              <div className={classes['line']}></div>
              <a href={proj.link} target="_blank" className={`${classes['line']} ${classes['line1']}`}></a>
              <iframe style={{borderRadius: 'inherit'}} width="100%" height="100%" src={proj.link} title={proj.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            )
          }
        </div>
      </div>
      <img id="arrow-right" className={`${classes['arrow']} ${classes['right']}`} src={arrowRight} alt="" />
    </div>
    <div className={classes['dots']}>
      <div id="dots" className={classes['dots-container']}>
      {
        new Array(13).fill(1).map((proj, index) => 
          <div
            key={proj + index}
            ref={(item) => dots.current[index] = item}  
            className={classes['dot']}
          >
          </div>
        )
      }
      </div>
    </div>

  </motion.section>
}