import classes from './style.module.scss';
import {PropsWithChildren} from "react";

interface Props {
  marginTop?: number;
}

export const Title = ({marginTop, children}: PropsWithChildren<Props>) : JSX.Element => {
  return <h2 style={{marginTop}} className={classes['title']}>
    {children}
  </h2>
};