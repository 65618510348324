import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { Title } from '../../components/Title';
import { useOnScreen } from '../../utils/UseOnScreen';
import classes from './style.module.scss';

export const Artists = () => {

  const rootRef = useRef<Element>() as any;
  const onScreen = useOnScreen(rootRef);
  const controls = useAnimation();
  useEffect(() => {
    if (onScreen) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: .5,
          ease: 'easeInOut'
        }
      });
    }

  }, [controls, onScreen]);

  const windowWidth = window.innerWidth;
  let marginTop = 187;

  if (windowWidth <= 670) {
    marginTop = 40
  }

  return <motion.section
    ref={rootRef}
    initial={{opacity: 0, y: -20}}
    animate={controls}
  >
    <Title marginTop={marginTop}>and music artists</Title>

    <div className={classes['artists-container']}>
      <div>
        <p>Wiz Khalifa</p>
        <p>MORGENSHTERN</p>
        <p>MARKUL</p>
        <p>ELDJEY</p>
        <p>SLAVA MARLOW</p>
      </div>
      <div>
        <p>Imanbek</p>
        <p>IRINA KAIRATOVNA</p>
        <p>KLAVA COCA</p>
        <p>The Limba</p>
        <p>MAYOT</p>
      </div>
      <div>
        <p>IVAN DORN</p>
        <p>Коста лакоста</p>
        <p>Thomas Mraz</p>
        <p>DANYA MILOKHIN</p>
        <p>JULIA GAVRILINA</p>
      </div>
    </div>
  </motion.section>
}