import { Title } from '../../components/Title';
import classes from './style.module.scss';
import logo1 from '../../assets/images/logo1.jpg';
import logo2 from '../../assets/images/logo2.png';
import logo3 from '../../assets/images/logo3.png';
import logo4 from '../../assets/images/logo4.jpg';
import logo5 from '../../assets/images/logo5.jpg';
import logo6 from '../../assets/images/logo6.jpg';
import logo7 from '../../assets/images/logo7.jpg';
import logo8 from '../../assets/images/logo8.png';
import logo9 from '../../assets/images/logo9.jpg';
import logo10 from '../../assets/images/logo10.png';
import logo11 from '../../assets/images/logo11.jpg';
import logo12 from '../../assets/images/logo12.png';
import logo13 from '../../assets/images/logo13.png';
import logo14 from '../../assets/images/logo14.png';
import { useEffect, useRef } from 'react';
import { useOnScreen } from '../../utils/UseOnScreen';
import { motion, useAnimation } from 'framer-motion';

export const Brands = () => {

  const rootRef = useRef<Element>() as any;
  const onScreen = useOnScreen(rootRef);
  const controls = useAnimation();
  useEffect(() => {
    if (onScreen) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: .5,
          ease: 'easeInOut'
        }
      });
    }

  }, [controls, onScreen]);

  const windowWidth = window.innerWidth;
  let marginTop = 187;

  if (windowWidth <= 670) {
    marginTop = 70
  }

  return <motion.section
    ref={rootRef}
    initial={{opacity: 0, y: -20}}
    animate={controls}
  >
    <Title marginTop={marginTop}><span className='highlight'>brands</span> <br /> we work with</Title>

      <div className={classes['line']}>
        <div className={classes['images-container']}>
          <div className={classes['mock-image']}>
            <img src={logo1} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo2} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo3} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo4} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo5} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo6} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo7} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo8} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo1} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo2} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo3} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo4} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo5} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo6} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo7} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo8} alt="" />
          </div>
        </div>
      </div>
      <div className={classes['line']}>
        <div className={classes['images-container1']}>
          <div className={classes['mock-image']}>
            <img src={logo9} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo10} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo11} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo12} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo13} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo14} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo9} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo10} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo11} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo12} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo13} alt="" />
          </div>
          <div className={classes['mock-image']}>
            <img src={logo14} alt="" />
          </div>
        </div>
      </div>
  </motion.section>
};